import Color from 'color';

import type { SecclTheme } from './theme';
import { secclThemes } from './theme';

/**
 * Determines the appropriate contrast color (light or dark) based on the brightness of the provided HEX color.
 *
 * @param {Color} hexColor - The color object.
 * @returns {string} A HEX string representing the contrast color.
 */
export function getContrastColor(hexColor: Color): string {
  return hexColor.isLight() ? '#272433' : '#FFFFFF';
}

type SecclThemeWithContrast = `${SecclTheme}-contrast`;

export function createRootCSSVariables(overrideColors: Partial<Record<SecclTheme | SecclThemeWithContrast, string>>) {
  let cssText = '';

  const colorMap = {
    '--seccl-color-black': '#000',
    '--seccl-color-white': '#fff',
    '--seccl-color-brand-primary': '#1d1739',
    '--seccl-color-brand-secondary': '#f85c88',
    '--seccl-color-grey-1': '#f6f5f7',
    '--seccl-color-grey-2': '#c9c6cc',
    '--seccl-color-grey-3': '#9d9aa2',
    '--seccl-color-grey-4': '#73707b',
    '--seccl-color-grey-5': '#4c4856',
    '--seccl-color-grey-6': '#272433',
    '--seccl-color-positive': '#219653',
    '--seccl-color-warning': '#ff8446',
    '--seccl-color-warning-yellow': '#fb0',
    '--seccl-color-yellow': '#fff500',
    '--seccl-color-negative': '#da2f29',
    '--seccl-color-info': '#0573a9',
    '--seccl-color-accent': '#0573a9',
    '--seccl-app-background': '#f4f3f5',
  };

  const addCSSProperty = (key: string, val: string) => {
    cssText += `${key}: ${val} !important;`;
  };

  for (const theme of secclThemes) {
    const hexColor = overrideColors?.[theme] ?? colorMap[`--seccl-color-${theme}`];

    if (!hexColor) {
      continue;
    }

    const colorObj = Color(hexColor);

    const contrast = overrideColors?.[`${theme}-contrast`] ?? getContrastColor(colorObj);

    addCSSProperty(`--seccl-color-${theme}`, colorObj.hex());
    addCSSProperty(`--seccl-color-${theme}-light`, colorObj.mix(Color('white'), 0.8).hex());
    addCSSProperty(`--seccl-color-${theme}-lighter`, colorObj.mix(Color('white'), 0.92).hex());
    addCSSProperty(`--seccl-color-${theme}-dark`, colorObj.darken(0.2).hex());
    addCSSProperty(`--seccl-color-${theme}-darker`, colorObj.darken(0.6).hex());
    addCSSProperty(`--seccl-color-${theme}-contrast`, contrast);
    addCSSProperty(`--seccl-color-${theme}-contrast-hover`, Color(contrast).alpha(0.1).rgb().string());
  }

  return `:root { ${cssText} }`;
}

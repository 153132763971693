import * as React from 'react';
import type { CustomContentProps, SnackbarKey } from 'notistack';
import { SnackbarContent, useSnackbar } from 'notistack';

import type { SecclTheme } from '../../utils/theme';
import { Icons } from '../icons';

import './toast.css';

export interface IToastProps extends CustomContentProps {
  title: string; // The title of the toast.
  action?: (key: SnackbarKey) => React.ReactNode;
}

/**
 * A toast component for displaying notifications.
 * Default positioning of toast can be changed within SecclUIConfigProvider or using the anchorOrigin prop for positioning on a per toast basis.
 * You can also find the autoHideDuration setting within toastProvider.component.tsx
 *
 * @component
 * @param {IToastProps} props - The props for the Toast component.
 * @param {React.Ref<HTMLDivElement>} ref - Forwarded ref.
 * @returns {React.ReactElement} The rendered Toast component.
 */

const ToastComponent: React.ForwardRefRenderFunction<HTMLDivElement, IToastProps> = (
  { message, title, id, variant, iconVariant, action },
  ref
) => {
  const { closeSnackbar } = useSnackbar();

  /**
   * Maps the variant prop to the corresponding SecclTheme color.
   * @param {CustomContentProps['variant']} variant - The variant prop value.
   * @returns {SecclTheme} The corresponding SecclTheme color.
   */
  const mapSeverity = (variant: CustomContentProps['variant'] | undefined): SecclTheme => {
    switch (variant) {
      case 'success':
        return 'positive';
      case 'error':
        return 'negative';
      case 'info':
        return 'info';
      case 'warning':
        return 'warning';
      default:
        return 'info';
    }
  };

  const handleDismiss = React.useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  /**
   * Generates a CSS style object with custom CSS variable values for toast colors.
   * @param {SecclTheme} severity - The color identifier.
   * @param {React.CSSProperties} [style={}] - Existing style object to extend.
   * @returns {React.CSSProperties} The generated style object.
   */
  const setStyle = (severity: SecclTheme) =>
    ({
      '--seccl-toast-color': `var(--seccl-color-${severity})`,
      '--seccl-toast-light': `var(--seccl-color-${severity}-lighter)`,
      '--seccl-toast-dark': `var(--seccl-color-${severity}-dark)`,
    } as React.CSSProperties);

  return (
    <SnackbarContent
      style={setStyle(mapSeverity(variant))}
      className="seccl-toast"
      data-testid="seccl-toast"
      ref={ref}
      role="alert"
    >
      <div className="seccl-toast-top-line">
        <div className="seccl-toast-icon">{iconVariant[variant]}</div>
        <span className="seccl-toast-title">{title}</span>
        <Icons.Close
          className="seccl-toast-close"
          aria-label="Close toast"
          role="button"
          onClick={handleDismiss}
          size={20}
        />
      </div>
      {message && <div className="seccl-toast-message">{message}</div>}
      {action && action(id)}
    </SnackbarContent>
  );
};

/**
 * A toast component for displaying notifications.
 */
export const Toast = React.forwardRef(ToastComponent);

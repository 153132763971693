export const secclThemes = [
  'brand-primary',
  'brand-secondary',
  'positive',
  'warning',
  'warning-yellow',
  'yellow',
  'negative',
  'info',
  'accent',
  'grey-1',
  'grey-2',
  'grey-3',
  'grey-4',
  'grey-5',
  'grey-6',
] as const;

export type SecclTheme = (typeof secclThemes)[number];

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Removes key/value pairs from dictionary objects where the value is null or undefined
 * @param object The object to check
 * @returns A new object with the relevant key/value pairs removed
 */
export function stripNullOrUndefined<TObject extends object | undefined>(object: TObject): TObject {
  if (!object) {
    return object;
  }
  return Object.entries(object).reduce(
    (finalObject, [key, val]) => ({ ...finalObject, ...(val !== null && val !== undefined ? { [key]: val } : {}) }),
    {} as TObject
  );
}

/**
 * This function takes an object and returns a new object with flattened keys.
 * Note: This function doesn't flatten arrays.
 *
 * @param {Object} obj - The object to flatten.
 * @param {string} [prefix=''] - The prefix to add before each key.
 *
 * @returns The new object with flattened keys.
 */
export function flattenObject(obj: any, prefix = ''): Record<string, any> {
  return Object.keys(obj).reduce((accumulator: Record<string, any>, key) => {
    const newPrefix = prefix ? prefix + '.' + key : key;

    // If the current key points to an object & not an array, then recursively flatten.
    // Otherwise, just add the key-value pair to the accumulator
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      Object.assign(accumulator, flattenObject(obj[key], newPrefix));
    } else {
      accumulator[newPrefix] = obj[key];
    }

    return accumulator;
  }, {});
}

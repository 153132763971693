import { BsCircleHalf, BsFillCheckCircleFill, BsThreeDots } from 'react-icons/bs';
import {
  FaCalendar,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaDownload,
  FaFilter,
  FaInfoCircle,
  FaLink,
  FaTable,
  FaUser,
} from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImSpinner8 } from 'react-icons/im';
import { IoEllipse } from 'react-icons/io5';
import {
  MdArrowBack,
  MdArrowDownward,
  MdArrowUpward,
  MdClose,
  MdDelete,
  MdDragIndicator,
  MdEdit,
  MdOutlineAdd,
  MdSearch,
  MdSettings,
} from 'react-icons/md';
import { RiAlertFill } from 'react-icons/ri';
import { TbCircleDashed } from 'react-icons/tb';

export const Icons = {
  Edit: MdEdit,
  Delete: MdDelete,
  Drag: MdDragIndicator,
  ChevronDown: FaChevronDown,
  ChevronUp: FaChevronUp,
  ArrowUp: MdArrowUpward,
  ArrowDown: MdArrowDownward,
  ChevronRight: FaChevronRight,
  ChevronLeft: FaChevronLeft,
  ArrowBackward: MdArrowBack,
  Spinner: ImSpinner8,
  Search: MdSearch,
  CheckCircle: BsFillCheckCircleFill,
  Alert: RiAlertFill,
  Ellipse: IoEllipse,
  Ellipses: BsThreeDots,
  Progress: TbCircleDashed,
  HamburgerMenu: GiHamburgerMenu,
  User: FaUser,
  Calendar: FaCalendar,
  Settings: MdSettings,
  Close: MdClose,
  Table: FaTable,
  Add: MdOutlineAdd,
  Info: FaInfoCircle,
  Link: FaLink,
  Filter: FaFilter,
  Download: FaDownload,
  CircleHalf: BsCircleHalf,
};

export type IconKeys = keyof typeof Icons;

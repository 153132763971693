import type { PropsWithChildren } from 'react';
import React from 'react';
import type { SnackbarProviderProps } from 'notistack';
import { SnackbarProvider } from 'notistack';

import { useUIConfig } from '../config';
import { Toast } from '../toast';

/**
 * Extends the 'notistack' module to add custom toast properties.
 */

declare module 'notistack' {
  type ToastCustomProps = {
    title: string;
  };

  interface VariantOverrides {
    default: false;
    warning: ToastCustomProps;
    success: ToastCustomProps;
    successAction: ToastCustomProps;
    info: ToastCustomProps;
    error: ToastCustomProps;
  }
}

/**
 * A provider for displaying toast notifications.
 *
 * @param {PropsWithChildren<SnackbarProviderProps>} props - The props for the ToastProvider component.
 * @returns {React.ReactElement} The rendered ToastProvider component.
 */
export const ToastProvider: React.FC<PropsWithChildren<SnackbarProviderProps>> = ({
  children,
  autoHideDuration = 5000,
  ...config
}) => {
  const { toastPosition, infoIcon, alertIcon, successIcon } = useUIConfig();

  return (
    <SnackbarProvider
      {...config}
      iconVariant={{
        success: successIcon,
        error: alertIcon,
        info: infoIcon,
        warning: alertIcon,
      }}
      maxSnack={1}
      Components={{
        success: Toast,
        error: Toast,
        info: Toast,
        warning: Toast,
      }}
      autoHideDuration={autoHideDuration}
      anchorOrigin={toastPosition}
    >
      {children}
    </SnackbarProvider>
  );
};

import * as React from 'react';
import type { SnackbarProviderProps } from 'notistack';

import SECCL_LOGO from '../../assets/seccl_logo.svg';
import { stripNullOrUndefined } from '../../utils/objects';
import { Icons } from '../icons/icons';
/**
 * Seccl UI global config type
 */
export interface ISecclUIConfigContext {
  /** the icon to use for the spinner */
  spinnerIcon?: React.ReactNode;
  /** The local or remote path to a logo */
  logoPath?: string;
  infoIcon?: React.ReactNode;
  alertIcon?: React.ReactNode;
  successIcon?: React.ReactNode;
  toastPosition?: SnackbarProviderProps['anchorOrigin'];
}

/**
 * System level defaults for seccl global config
 */
const systemDefaults: Required<ISecclUIConfigContext> = {
  spinnerIcon: <Icons.Spinner />,
  logoPath: SECCL_LOGO,
  infoIcon: <Icons.Info />,
  alertIcon: <Icons.Alert />,
  successIcon: <Icons.CheckCircle />,
  toastPosition: { horizontal: 'right', vertical: 'bottom' },
};

const UIConfigContext = React.createContext<Required<ISecclUIConfigContext>>(systemDefaults);

/**
 * Configuration for Seccl UI
 * - Allows for certain frequently repeated properties to be set at a global level
 */
export const SecclUIConfigProvider: React.FC<React.PropsWithChildren<ISecclUIConfigContext>> = ({
  children,
  ...config
}) => {
  return (
    <UIConfigContext.Provider value={{ ...systemDefaults, ...stripNullOrUndefined(config) }}>
      {children}
    </UIConfigContext.Provider>
  );
};

/**
 * Used to access settings which can be stored globally as Seccl UI config
 * WARNING: For internal use only
 * @param localOverrides Optional overrides for config properties, these will take precedence over anything set globally.
 * @returns A config dictionary with a guaranteed entry for every key in priority order: {...system, ...global, ...local}
 */
export const useUIConfig = (localOverrides?: ISecclUIConfigContext): Required<ISecclUIConfigContext> => {
  const config = React.useContext(UIConfigContext);
  return { ...config, ...stripNullOrUndefined(localOverrides) };
};

import React from 'react';
import { StyledEngineProvider } from '@mui/material';
import type { Breakpoint } from '@mui/material/styles';
import { extendTheme, ThemeProvider } from '@mui/material/styles';

import type { ISecclUIConfigContext } from '../config';
import { SecclUIConfigProvider } from '../config';
import { ToastProvider } from './toastProvider.component';

const theme = (customBreakpoints?: Record<Breakpoint, number>) =>
  extendTheme({
    typography: {
      allVariants: {
        fontFamily: 'var(--seccl-primary-font)',
      },
    },
    breakpoints: {
      values: customBreakpoints,
    },
  });

/** Props type for the unified Seccl provider */
export interface ISecclProviderProps {
  /** A dictionary of optional global config, overrides the system defaults */
  config?: ISecclUIConfigContext;
  /** A dictionary of breakpoints to override */
  customBreakpoints?: Record<Breakpoint, number>;
}

/**
 * The `SecclProvider` is a React component that wraps the Material-UI's `StyledEngineProvider`, `CssVarsProvider` and `SecclUIConfigProvider`.
 * It ensures that the styles injected by Material-UI are loaded first so that it overrides the default theming system. And allows global config to be set and shared between all components
 */
export const SecclProvider: React.FC<React.PropsWithChildren<ISecclProviderProps>> = ({
  children,
  config,
  customBreakpoints,
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customBreakpoints)} disableStyleSheetGeneration>
        <ToastProvider>
          <SecclUIConfigProvider {...config}>{children}</SecclUIConfigProvider>
        </ToastProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
